<template>
  <div class="full-screen" @click="toFullScreen">Toggle-Fullscreen</div>
  <div class="anweisung">
    <h1 id="message">{{ message }}</h1>
    <div class="progress">
      <div
        class="progress-bar"
        :style="'width: ' + progress + '%;'"
        id="progressbar"
      ></div>
      <h2>{{ timeLeft }}</h2>
    </div>
  </div>
  <confirm-modal
    v-show="showConfirm"
    :gameConfig="gameConfig"
    :confirmData="confirmData"
    :field="field"
    :image="image"
    :tableId="tableId"
    @abort="abortConfirm"
    @confirm="handleConfirm"
    ref="confirmModal"
  />
  <no-croupier v-if="noCroupier" />
</template>

<script>
import ConfirmModal from "./components/ConfirmModal.vue";
import NoCroupier from "./components/NoCroupier.vue";
import { v4 as uuidv4 } from "uuid";

// const APIURL = "https://90178-05773.pph-server.de:4000";
const APIURL = "https://77-74-195-194.as42831.net:4000";

const GAME_STATE = {
  PRE_START: 1,
  RUNNING: 2,
  BETS_CLOSED: 3,
  WAIT_CONFIRM: 4,
  CONFIRM_ABORT: 5,
  CONFIRMED: 6,
  CLOSED: 7,
  BREAK: 8,
  MAINTENANCE: 9,
};

export default {
  name: "App",
  components: { ConfirmModal, NoCroupier },
  data() {
    return {
      tableId: Number,
      gameConfig: Object,
      field: Number,
      state: Number,
      progress: Number,
      timeLeft: "",
      startTime: null,
      endTime: null,
      duration: null,
      progressBar: null,
      message: "",
      messageh1: null,
      noCroupier: false,

      showConfirm: false,
      ctx: null,
      canvas: null,
      confirmModal: null,
      confirmData: {
        start: 0,
        duration: 0,
        field: -1,
      },
      image: null,
      cookie: "",
    };
  },
  methods: {
    async abortConfirm() {
      this.showConfirm = false;
      this.field = -1;
      {
        const URL = APIURL + "/api/croupmon/abortconfirm?id=" + this.tableId;
        try {
          await fetch(URL, {
            method: "GET",
            headers: {
              Application: "json",
              tableuuid: this.cookie,
            },
          });
        } catch (error) {
          console.log(error);
          // console.log(error);
        }
      }
    },
    async getConfirm() {
      const URL = APIURL + "/api/croupmon/toconfirm?id=" + this.tableId;
      try {
        const response = await fetch(URL, {
          method: "GET",
          headers: {
            Application: "json",
            tableuuid: this.cookie,
          },
        });
        if (response.status == 200) {
          const data = await response.json();
          this.noCroupier = data.croupierId == -1;
          this.state = data.state;
          switch (this.state) {
            case GAME_STATE.RUNNING:
              this.message = "RUNNING";
              this.messageh1.style.color = "#00FF00";
              break;
            case GAME_STATE.BETS_CLOSED:
              this.message = "WAITING";
              this.messageh1.style.color = "#FF0000";
              break;
            case GAME_STATE.CONFIRMED:
              this.message = "END";
              this.messageh1.style.color = "#00FFFF";
              break;
            case GAME_STATE.PRE_START:
              this.message = "!!! START !!!";
              this.messageh1.style.color = "#00FF00";
              break;
            case GAME_STATE.BREAK:
              this.message = "!!! PAUSE !!!";
              this.messageh1.style.color = "#00FF00";
              break;
            case GAME_STATE.CLOSED:
              this.message = "!!! CLOSED !!!";
              this.messageh1.style.color = "#00FF00";
              break;
            case GAME_STATE.WAIT_CONFIRM:
              this.message = "CONFIRMATION";
              this.messageh1.style.color = "#00FF00";
              break;
          }

          if (
            this.state === GAME_STATE.RUNNING ||
            this.state === GAME_STATE.PRE_START ||
            this.state === GAME_STATE.WAIT_CONFIRM
          ) {
            this.startTime = Date.now() + data.gameState.start;
            this.duration = data.gameState.duration;
            this.endTime = this.startTime + this.duration;
            const tls = Math.floor((this.endTime - Date.now()) / 1000);
            const mins = Math.floor(tls / 60);
            const secs = tls % 60;
            this.timeLeft =
              ("00" + mins.toString()).slice(-2) +
              ":" +
              ("00" + secs.toString()).slice(-2);

            const steps1 = 100 / this.duration;
            const tmp = (Date.now() - this.startTime) * steps1;
            this.progressBar.style.width = tmp + "%";
          } else {
            this.timeLeft = "00:00";
          }
          if (data.state === GAME_STATE.WAIT_CONFIRM) {
            if (
              data.imageUrl &&
              data.imageUrl !== "" &&
              this.image !== APIURL + "/" + data.imageUrl
            ) {
              this.image = APIURL + "/" + data.imageUrl;
            }
            this.showConfirm = true;
            const tmp = {
              start: data.gameState.start,
              duration: data.gameState.duration,
              field: data.confirmField,
            };
            this.confirmData = tmp;
            this.field = data.confirmField;
          } else if (
            data.state === GAME_STATE.CONFIRM_ABORT &&
            this.field > -1
          ) {
            this.showConfirm = true;
          } else this.showConfirm = false;
        }
      } catch (error) {
        console.log(error);
      }
      //     const data = await response.json();
      //     if (data.state === GAME_STATE.WAIT_CONFIRM) {
      //       if (
      //         data.imageUrl &&
      //         data.imageUrl !== "" &&
      //         this.image !== APIURL + "/" + data.imageUrl
      //       ) {
      //         this.image = APIURL + "/" + data.imageUrl;
      //       }
      //       this.showConfirm = true;
      //       const tmp = {
      //         start: data.gameState.start,
      //         duration: data.gameState.duration,
      //         field: data.confirmField,
      //       };
      //       this.confirmData = tmp;
      //       this.field = data.confirmField;
      //     } else if (
      //       data.state === GAME_STATE.CONFIRM_ABORT &&
      //       this.field > -1
      //     ) {
      //       this.showConfirm = true;
      //     } else this.showConfirm = false;
      //   }
      // } catch (error) {
      //   console.log(error);
      //   // console.log(error);
      // }
    },
    async handleConfirm() {
      this.showConfirm = false;
      const URL =
        APIURL +
        "/api/croupmon/confirm?id=" +
        this.tableId +
        "&resNr=" +
        this.field;
      try {
        await fetch(URL, {
          method: "GET",
          headers: {
            Application: "json",
            tableuuid: this.cookie,
          },
        });
      } catch (error) {
        console.log(error);
      }
    },
    toFullScreen() {
      var doc = window.document;
      var docEl = doc.documentElement;

      var requestFullScreen =
        docEl.requestFullscreen ||
        docEl.mozRequestFullScreen ||
        docEl.webkitRequestFullScreen ||
        docEl.msRequestFullscreen;
      if (
        !doc.fullscreenElement &&
        !doc.mozFullScreenElement &&
        !doc.webkitFullscreenElement &&
        !doc.msFullscreenElement
      ) {
        requestFullScreen.call(docEl);
      }
    },
    async getGameConfig() {
      const URL = APIURL + "/api/croupmon/getGameConfig?id=" + this.tableId;
      try {
        const response = await fetch(URL, {
          method: "GET",
          headers: {
            Application: "json",
            tableuuid: this.cookie,
          },
        });
        if (response.status == 200) {
          const data = await response.json();
          this.gameConfig = data;
          await this.getConfirm();
          setInterval(async () => {
            await this.getConfirm();
          }, 500);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.cookie = localStorage.getItem("table-uuid") || uuidv4();
    localStorage.setItem("table-uuid", this.cookie);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get("id");
    this.tableId = id;
    this.getGameConfig();
    this.confirmModal = this.$refs.confirmModal;
    this.progressBar = document.getElementById("progressbar");
    this.messageh1 = document.getElementById("message");
  },
};
</script>

<style>
:root {
  --color-primary: #7380ec;
  --color-danger: #ff7782;
  --color-success: #41f1b6;
  --color-warning: #ffbb55;
  --color-white: #fff;
  --color-info-dark: #7d8da1;
  --color-info-light: #dce1eb;
  --color-dark: #363949;
  --color-light: rgba(141, 150, 236, 0.253);
  --color-primary-variant: #111e88;
  --color-dark-variant: #677483;
  --color-background: #131a21;
  --color-bg-navi: #0f141b;
  /* --color-bg-card: #161f29; */
  --color-bg-card: rgba(0, 0, 0, 0.2);
  --color-red: rgb(255, 0, 0);
  --color-black: rgb(255, 255, 255);
  --color-green: rgb(0, 255, 0);

  --card-border-radius: 2rem;
  --border-radius-1: 0.4rem;
  --border-radius-2: 0.8rem;
  --border-radius-3: 1.2rem;

  --card-padding: 1rem;
  --padding-1: 1.2rem;

  --box-shaddow: 0 2rem 3rem var(--color-light);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 14px;
}

body {
  width: 100vw;
  height: 100vh;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  color: var(--color-white);
  font-size: 0.88rem;
  background: var(--color-background);
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: var(--color-dark);
}

img {
  display: block;
  width: 100%;
}

h3 {
  font-size: 0.87rem;
}

h4 {
  font-size: 0.8rem;
}

h5 {
  font-size: 0.77rem;
}

.small {
  font-size: 0.75rem;
}

.text-muted {
  color: var(--color-info-dark);
}

p {
  color: var(--color-dark-variant);
}

b {
  color: var(--color-dark);
}

.primary {
  color: var(--color-primary);
}

.danger {
  color: var(--color-danger);
}

.warning {
  color: var(--color-warning);
}

.success {
  color: var(--color-success);
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.right {
  text-align: right !important;
}

.left {
  text-align: left !important;
}

.left-right-info {
  width: 100%;
}

.game-info {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 5px;
  place-items: space-between;
}

.left-right-info :nth-child(odd) {
  text-align: left;
}

.left-right-info :nth-child(even) {
  float: right;
  text-align: right !important;
}

.middle-font {
  font-weight: 600;
  font-size: 1rem;
}

.card-caption {
  display: flex;
  gap: 1rem;
}

.card-caption span {
  font-size: 1.8rem;
}

.red {
  color: var(--color-red);
}

.black {
  color: var(--color-black);
}

.green {
  color: var(--color-green);
}

.full-screen {
  font-weight: 600;
  font-size: 1rem;
  background-color: var(--color-bg-card);
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: black;
  color: red;
}

.anweisung {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.anweisung .progress {
  width: 100vw;
  height: 120px;
  /* background-color: aqua; */
}

.anweisung .progress .progress-bar {
  position: relative;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  background: yellowgreen;
  transition: 50ms;
}

.anweisung .progress h2 {
  position: absolute;
  left: 50%;
  top: 60%;
  transform: translate(-50%);
}

h1 {
  font-weight: 900;
  font-size: 10rem;
}

h2 {
  font-weight: 900;
  font-size: 6.5rem;
}
</style>
