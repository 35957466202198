<template>
  <div class="modal">
    <div class="modal-content">
      <div class="content">
        <h2 class="text-red text-center">!!! Not Loggedin !!!</h2>
      </div>
    </div>
  </div>
</template>

<script setup></script>

<style scoped>
.modal {
  display: block;
  position: fixed;
  z-index: 1;
  padding-top: 50px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  position: relative;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  background-color: rgba(0, 0, 0, 0.8);
  width: 80%;
}

.content {
  width: 100%;
  height: 100%;
}

.text-center {
  text-align: center;
}
</style>
