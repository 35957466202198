<template>
  <div class="modal">
    <div class="modal-content">
      <div class="content">
        <div class="number" :class="getWinNrClass(field)">
          {{ getWinNrCaption(field) }}
        </div>
        <div class="progress">
          <div class="progress-bar" id="progess-bar" :ref="prgbar"></div>
          <div class="timer">
            {{ timeLeft }}
          </div>
        </div>
        <div class="img-container">
          <canvas class="img" id="img" ref="canvas"></canvas>
        </div>
        <div class="confirm">
          <a
            nohref
            class="btn"
            :class="conf_state == 1 ? 'btn-success' : 'btn-warning'"
            @click="conf_state = 1"
            >CONFIRM</a
          >
          <a nohref class="btn btn-danger" @click="$emit('abort')">ABORT</a>
          <a nohref class="btn btn-warning" @click="handleConfirm">CONFIRM</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const vidWidth = 480;
const vidHeight = 270;

export default {
  name: "ConfirmModal",
  props: {
    field: Number,
    gameConfig: Object,
    confirmData: Object,
    image: String,
    tableId: Number,
  },
  data() {
    return {
      startTime: 0,
      duration: 0,
      timeLeft: "",
      conf_state: 0,
    };
  },
  watch: {
    field: function () {
      this.conf_state = 0;
    },
    confirmData: function () {
      const prg = document.getElementById("progess-bar");
      this.startTime = Date.now() + this.confirmData.start;
      this.endTime = this.startTime + this.duration;
      this.duration = this.confirmData.duration;
      const tls = Math.floor((this.endTime - Date.now()) / 1000);
      const mins = Math.floor(tls / 60);
      const secs = tls % 60;
      this.timeLeft =
        ("00" + mins.toString()).slice(-2) +
        ":" +
        ("00" + secs.toString()).slice(-2);
      const steps1 = 100 / this.duration;
      const tmp = (Date.now() - this.startTime) * steps1;
      prg.style.width = tmp + "%";
    },
    image: function () {
      console.log("watchImage " + this.image);
      if (this.image) this.showImage();
    },
  },
  methods: {
    getWinNrClass(winNr) {
      if (winNr !== null && winNr >= 0 && winNr <= 37) {
        return this.gameConfig.fields[winNr].color;
      }
    },
    getWinNrCaption(winNr) {
      if (winNr !== null && winNr >= 0 && winNr <= 37) {
        return this.gameConfig.fields[winNr].caption;
      }
    },
    handleConfirm() {
      if (this.conf_state == 1) {
        this.$emit("confirm", this.field);
        this.conf_state = 0;
      }
    },
    getVideoArea() {
      const src = {};
      switch (this.tableId) {
        case 4: {
          const cpW = 780; //TableId 4
          const cpH = Math.floor(cpW / 1.7777777777778);
          src.x = 1920 / 2 - cpW / 2 - 50;
          src.y = 1080 / 2 - cpH / 2 + 30;
          src.w = cpW;
          src.h = cpH;
          break;
        }
        case 5: {
          const cpW = 1200; //TableId 5;
          const cpH = Math.floor(cpW / 1.7777777777778);
          src.x = 1920 / 2 - cpW / 2 + 50;
          src.y = 1080 / 2 - cpH / 2;
          src.w = cpW;
          src.h = cpH;
          break;
        }
        case 6: {
          const cpW = 1400; //TableId 6
          const cpH = Math.floor(cpW / 1.7777777777778);
          src.x = 1920 / 2 - cpW / 2;
          src.y = 1080 / 2 - cpH / 2 - 30;
          src.w = cpW;
          src.h = cpH;
          break;
        }
        case 1:
        case 2:
        case 3:
        default: {
          const cpW = 700; //TableId 1,2,3
          const cpH = Math.floor(cpW / 1.7777777777778);
          src.x = 1920 / 2 - cpW / 2;
          src.y = 1080 / 2 - cpH / 2;
          src.w = cpW;
          src.h = cpH;
          break;
        }
      }
      return src;
    },
    showImage() {
      const tmp = new Image();
      const src = this.getVideoArea();
      tmp.onload = function () {
        const cv = document.getElementById("img");
        cv.width = vidWidth;
        cv.height = vidHeight;
        const ctx = cv.getContext("2d");
        ctx.width = vidWidth;
        ctx.height = vidHeight;
        ctx.drawImage(
          tmp,
          src.x,
          src.y,
          src.w,
          src.h,
          0,
          0,
          vidWidth,
          vidHeight
        );
      };
      tmp.src = this.image;
    },
  },
  mounted() {
    this.conf_state = 0;
    this.prg = this.$refs.prgbar;
  },
};
</script>

<style scoped>
.modal {
  display: block;
  position: fixed;
  z-index: 1;
  padding-top: 50px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  position: relative;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  background-color: rgba(0, 0, 0, 0.8);
  width: 80%;
}

.content {
  width: 100%;
  height: 100%;
}

.confirm {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.btn {
  text-decoration: none;
  font-weight: 600;
  font-size: 1.3rem;
  color: white;
  text-align: center;
  padding: 0.5rem;
  width: 100%;
  height: 2.6rem;
  background: black;
  border-radius: 10px;
  border: 1px solid black;
  color: #222222;
  cursor: pointer;
}

.btn-warning {
  background-color: var(--color-warning);
}

.btn-danger {
  background: var(--color-danger);
}

.btn-success {
  background: var(--color-success);
}

hr {
  margin-top: 4px;
  margin-bottom: 4px;
}

.number {
  text-align: center;
  font-weight: 800;
  font-size: 40px;
}

.progress {
  position: relative;
  width: 100%;
  overflow: hidden;
}

#progess-bar {
  height: 30px;
  background-color: green;
  width: 50%;
}

.timer {
  position: absolute;
  left: 50%;
  top: 0px;
  text-align: center;
  font-weight: 700;
  font-size: 30px;
  transform: translateX(-50%);
}

.img-container {
  width: 480px;
  height: 270px;
}
.img {
  position: absolute;
  left: 50%;
  width: 480px;
  height: 270px;
  transform: translateX(-50%);
}

.himg {
  display: none;
  width: 1920px;
  height: 1080px;
}
</style>
